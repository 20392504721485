<template>
  <div id="dasshpe">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t('deposit.dasshpe.headerUPI') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/dasshpe_upi.png" alt />
              <p>{{ $t('deposit.method.desc', { method: $t('deposit.default.channels.dasshpeUPI') }) }}</p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>{{ $t('deposit.dasshpe.inst1') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">
                {{ $t('deposit.method.form.header', { method: $t('deposit.default.channels.dasshpeUPI') }) }}
              </p>
              <el-form label-position="top" :model="dasshpeForm" ref="dasshpeForm" :rules="dasshpeRules" status-icon>
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        supportedCurrencies="USD"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="dasshpeForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.vpa')" prop="vpa">
                        <el-input v-model="dasshpeForm.vpa" data-testid="vpa" maxlength="100"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="dasshpeForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <p class="mb-2">
                        <span class="required_icon">*</span>
                        {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'INR' }) }}
                        <span>{{ rate.toLocaleString(undefined, { minimumFractionDigits: 2 }) }}</span>
                      </p>
                      <p>
                        <span class="required_icon">*</span> INR: <span>{{ rateChange }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
              <div v-html="bankHTML"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import indiaMixin from '@/mixins/page/deposit/india';
import rounding from '@/util/rounding';
import { autoForm } from '@/util/getAutoForm';
import { apiDasshpeUPI_payment } from '@/resource';

export default {
  name: 'Dasshpe',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, indiaMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        );
      } else {
        callback();
      }
    };
    return {
      dasshpeForm: {
        accountNumber: '',
        amount: '',
        notes: '',
        vpa: ''
      },
      dasshpeRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        vpa: {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.vpa') }),
          trigger: 'blur'
        }
      },
      bankHTML: '',
      maxLimit: 200000
    };
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.dasshpeForm.amount, 2);
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.dasshpeForm.accountNumber = accountNumber;
    },
    success(msg) {
      this.bankHTML = autoForm(msg);
      if (this.bankHTML.length > 0) {
        this.$nextTick(function () {
          if (document.getElementById('AutoForm')) {
            document.getElementById('AutoForm').submit();
          } else {
            this.loading = false;
            this.errorMessage(this.$t('deposit.default.failed'));
          }
        });
      } else {
        this.loading = false;
        this.errorMessage(this.$t('deposit.default.failed'));
      }
    },
    submitForm() {
      this.$refs['dasshpeForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiDasshpeUPI_payment(
        {
          mt4Account: this.dasshpeForm.accountNumber,
          operateAmount: this.dasshpeForm.amount,
          vpa: this.dasshpeForm.vpa,
          depositAmount: this.rateChange,
          rate: this.rate,
          applicationNotes: this.dasshpeForm.notes
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
